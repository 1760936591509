<template>
  <div>
    <div
      v-for="file in selectedDocItem[modalType] || []"
      :key="`file_${file?.id || 0}_${selectedDocItem.originalId || 'unknown'}`"
      :class="gridClass"
      class="flex flex-wrap md:grid gap-2 mb-2"
    >
      <a
        v-if="file.unsigned_file?.url"
        class="hse-modal__template-link w-full md:w-auto"
        :href="file.unsigned_file.url"
        target="_blank"
        :download="file.unsigned_file.name"
      >
        <span class="hse-modal__template-link-icon-clip" />
        <span class="word_break">{{
          file.is_document ? localeText.labelDocFile : localeText.labelAppFile
        }}</span>
        <span class="hse-modal__template-link-icon-import" />
      </a>

      <a
        v-if="file.signature_file?.url"
        class="hse-modal__template-link w-full md:w-auto"
        :href="file.signature_file.url"
        target="_blank"
        :download="file.signature_file.name"
      >
        <span class="hse-modal__template-link-icon-clip" />
        <span class="word_break">{{
          file.is_document
            ? localeText.labelDocSignature
            : localeText.labelAppSignature
        }}</span>
        <span class="hse-modal__template-link-icon-import" />
      </a>

      <a
        v-if="file.signed_file?.url"
        class="hse-modal__template-link w-full md:w-auto"
        :href="file.signed_file.url"
        target="_blank"
        :download="file.signed_file.name"
      >
        <span class="hse-modal__template-link-icon-clip" />
        <span class="word_break">
          {{ localeText?.localDocECP }}
        </span>
        <span class="hse-modal__template-link-icon-import" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalDocumentItem',
  components: {},
  props: {
    modalType: {type: String, default: 'files'},
    selectedDocItem: {type: Object, default: () => ({})},
    localeText: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    firstItem() {
      return (this.selectedDocItem[this.modalType] || [])[0] || {};
    },
    gridClass() {
      const itemCount = [
        this.firstItem?.unsigned_file?.url,
        this.firstItem?.signature_file?.url,
        this.firstItem?.signed_file?.url
      ].filter(Boolean).length;

      if (itemCount === 1) return 'md:grid md:grid-cols-1';
      if (itemCount === 2) return 'md:grid md:grid-cols-2';
      return 'md:grid md:grid-cols-3';
    }
  }
};
</script>
