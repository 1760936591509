<template>
  <div
    :class="{
      'inputWrap-password': true,
      'error-system': hasError,
    }"
  >
    <label :class="{ required: required }" for="password">
      {{ label }}
    </label>
    <input
      :id="id"
      v-model="value.$model"
      class="input-form__auth"
      :name="id"
      :type="showPassword ? 'text' : 'password'"
      :required="required"
      @input="$emit('input', $event)"
    />
    <div
      :class="{ active: showPassword }"
      class="show-password"
      @click="toggleShow"
    />
    <slot name="errors" />
  </div>
</template>

<script>
export default {
  name: 'InputPassword',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: () => ({ $model: null }),
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },

  computed: {
    hasError() {
      return this.value.$error || (this.value.$dirty && this.value.$invalid);
    },
  },

  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="scss">
.inputWrap-password {
  margin-bottom: 28px;
}

.inputWrap-password {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $dusk-gray-color;
    margin-bottom: 8px;
    position: relative;
    display: inline-flex;
    width: fit-content;
  }
  label.required:before {
    content: '*';
    position: absolute;
    top: 0;
    right: -6px;
    width: 5px;
    height: 5px;
    display: block;
  }
  .input-form__auth {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid $border-color;
    padding: 14px 45px 12px 14px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $base-black-color;
    transition: all 0.3s;
    box-sizing: border-box;
  }
  .show-password {
    width: 45px;
    height: 48px;
    padding: 0;
    position: absolute;
    top: 28px;
    right: 0;
    border: none;
    cursor: pointer;
    background: url('@/assets/icons/icon-eye.svg') no-repeat center center;
  }
  .show-password.active {
    background: url('@/assets/icons/icon-eye-open.svg') no-repeat center center;
  }
  .input-form__auth:focus-visible {
    border: 1px solid $blue-color;
  }
  &.error-system .input-form__auth:focus-visible {
    border-color: $error-system-red;
    outline: 1px solid $error-system-red;
  }
  &.error-system input {
    border: 1px solid $error-system-red;
  }
}
</style>
