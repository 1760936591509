import Axios from '@/utils/Elk-Axios';
import {SET_CART_ITEMS, SET_INIT_STATE, SET_LOADING} from './templates';
import {GET_CART, CLEAR_CART, REMOVE_CART, ADD_CART} from '@/api';
// import { fakeCartItems } from './fakeData'

export default {
  namespaced: true,
  state: {
    cartItems: [],
    isInit: false,
    isLoading: false
  },
  getters: {
    counterCartItems(state) {
      return state.cartItems.length || '';
    },
    isEmpty(state) {
      return state.cartItems.length === 0;
    },
    isLoading(state) {
      return state.isLoading;
    },
    cartItem: state => id => state.cartItems.find(e => e.id === id)
  },
  mutations: {
    [SET_CART_ITEMS](state, params) {
      state.cartItems = params ?? [];
    },
    [SET_INIT_STATE](state, value) {
      state.isInit = value ?? false;
    },
    [SET_LOADING](state, value) {
      state.isLoading = value ?? false;
    }
  },
  actions: {
    mapResponseToItems(_, responseItems) {
      if (!Array.isArray(responseItems) || !responseItems.length) {
        return [];
      }
      const normalizeDate = dateStr => {
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        };
        const tempDate = new Date(dateStr);
        let resultDate = '';
        if (tempDate instanceof Date && !isNaN(tempDate)) {
          resultDate = new Intl.DateTimeFormat('ru-Ru', options).format(
            tempDate
          );
        }
        return resultDate || '';
      };

      return responseItems.map(e => {
        let priceStart = 0;
        let priceEnd = e.price;

        if (e.auto_discount) {
          priceStart = e.auto_discount.price;
          priceEnd = e.auto_discount.discounted_price;
        } else if (e.price_detail?.discount_value) {
          priceStart = e.price_detail?.price;
          priceEnd = e.price_detail?.discounted_price;
        }

        return {
          id: e.id ?? 0,
          is_ordered: e.is_ordered ?? null,
          is_ordered_processing: e.is_ordered_processing ?? null,
          is_edu_level_need: e.is_edu_level_need ?? null,
          title: e.title ?? '',
          direction: e.specialization ?? '',
          duration: e.duration ?? '',
          isAutoDiscount: !!e.auto_discount,
          discountedReason: e.auto_discount
            ? e.auto_discount.discounted_reason
            : '',
          type: e.program_type ?? '',
          schedule: e.schedule.map(s => ({
            id: s.id,
            dateStartStr: normalizeDate(s.date_start),
            dateStart: s.date_start,
            dateEnd: s.date_end,
            offerUrl: s.offer_document ?? '',
            region: s.region ?? ''
          })),
          price: {
            start: priceStart,
            end: priceEnd
          },
          documentType: e.final_document ?? '',
          finLiteracy: e.fin_literacy ?? '',
          freeProgram: e.free_program ?? null,
          detail_url: e.detail_url ?? '',
          childrenCount: e.children_count ?? null,
          freeLearningReasons: e.freeLearningReasons ?? null,
          nds_rate: e.nds_rate ?? null
        };
      });
    },
    async clearCart({commit}) {
      const response = await Axios.delete(CLEAR_CART);
      if (response.data.data.cart_count === 0) {
        commit(SET_CART_ITEMS, []);
      }
    },
    async remove({commit, state, getters}, id) {
      const response = await Axios.post(REMOVE_CART, {
        program_id: id
      });
      if (response.data.data.cart_count === 0) {
        commit(SET_CART_ITEMS, []);
      } else if (response.data.data.cart_count < getters.counterCartItems) {
        commit(
          SET_CART_ITEMS,
          state.cartItems.filter(e => e.id !== id)
        );
      }
    },
    async add({dispatch}, id) {
      await Axios.post(ADD_CART, {
        program_id: id
      });
      dispatch('refreshCartItems');
    },
    async fetchCart() {
      const response = await Axios.get(GET_CART);
      // const response = {data: {data: fakeCartItems}}
      return response?.data?.data ?? [];
    },
    async refreshCartItems({dispatch, commit}) {
      let items = [];

      try {
        items = await dispatch('fetchCart');
      } catch {
        items = [];
      }

      dispatch('mapResponseToItems', items).then(r =>
        commit(SET_CART_ITEMS, r)
      );
    },
    async init({dispatch, commit, state}) {
      if (state.isInit) {
        return;
      }
      commit(SET_INIT_STATE, true);
      try {
        commit(SET_LOADING, true);
        await dispatch('refreshCartItems');
      } catch {
        commit(SET_CART_ITEMS, []);
      } finally {
        commit(SET_LOADING, false);
      }
    }
  }
};
