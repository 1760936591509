import Axios from './Elk-Axios';
import store from '@/store';
import {clearState} from '@/store/mutationsName';
import localStorageService from './localStorageService';
import {LOGOUT} from '@/api';
import setNotification from '@/utils/setNotification';
import {getCommonCaptionByName} from '@/i18n/utils';
import I18N from '@/i18n';

export default async () => {
  try {
    const idToken = localStorageService.getIdToken();
    const externalRedirect = localStorageService.getExternalRedirect();
    await Axios.get(LOGOUT);
    store.commit(clearState);
    delete Axios.defaults.headers.common['Authorization'];
    localStorageService.clear();
    if (externalRedirect) {
      localStorageService.setExternalRedirect(externalRedirect);
    }
    if (!idToken) {
      return location.assign('/');
    }
    return location.assign(store.getters.getAdfsLogOut(idToken));
  } catch (e) {
    if (!e.isHandled) {
      setNotification({
        message: I18N.t(getCommonCaptionByName('auth_logoutError'))
      });
    }
  }
};
